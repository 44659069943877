<template>
  <div>
    <div v-if="$userInfo.isSupportUser">
      <app-detail-header :show-spinner="false"
        :show-cancel="false"
        ref="sectionHeader" />
      <section class="container box mt-2">
        <div>
          <span class="subtitle">Available New Menus</span>
          <ul class="list-style-reset">
            <li v-for="menu in menus"
              :key="menu.menuCode">
              <span>{{ menu.menuName }}</span>
            </li>
          </ul>
        </div>
        <div class="pt-5">
          <button class="button is-primary"
            @click="grantNewMenusAccess()">Grant Access</button>
        </div>
      </section>

      <section class="container box mt-2">
        <div>
          <span class="subtitle">New Quote Details Screen
            <span class="mdi mdi-22px"
              :class="{'mdi-check-circle has-text-success' : $company.setting.newQuoteDetail, 'mdi-close-circle has-text-danger' : !$company.setting.newQuoteDetail }" />
          </span>
          <ul class="list-style-reset">
            <li>New Quote Builder</li>
            <li>Images, Summary, Docs, Comms, Notes & Status moved to side panel</li>
            <li>New Status panel to quickly change Job/Quote Stage</li>
            <li>New Booking Screen</li>
          </ul>

        </div>
        <div class="pt-5">
          <button class="button is-primary"
            :class="{'is-loading': isLoading}"
            :disabled="$company.setting.newQuoteDetail"
            @click="alterCompanySettings(settingOptions.newQuoteScreenAccess.name, settingOptions.newQuoteScreenAccess.enable)">Grant Access</button>

          <button class="button is-danger ml-2"
            :class="{'is-loading': isLoading}"
            :disabled="!$company.setting.newQuoteDetail"
            @click="alterCompanySettings(settingOptions.newQuoteScreenAccess.name, settingOptions.newQuoteScreenAccess.disable)">Revoke Access</button>
        </div>
      </section>
    </div>

    <div v-else
      class="is-flex is-justify-content-center pt-5 ">
      <div class="notification is-danger is-flex is-justify-content-center is-size-4"
        style="width: 50%">
        You do not have permissions on this page
      </div>
    </div>
  </div>
</template>

<script>
// import AppDetailHeader from '@/components/AppDetailHeader'
import Menus from './new-menus'
import CompanySettingOptions from './company-settings-options'
import { CompanyService } from '../../../services'

export default {
  name: 'CompanyEnableNewMenu',
  components: {
    // AppDetailHeader
  },
  mixins: [],
  props: {},
  data: () => {
    return {
      isLoading: false
    }
  },
  computed: {
    menus() {
      return Menus
    },
    settingOptions: () => {
      return CompanySettingOptions
    }
  },
  watch: {},
  created() {},
  mounted() {},
  beforeDestroy() {},
  methods: {
    async grantNewMenusAccess() {
      try {
        this.$showSpinner()
        let promises = []
        this.menus.forEach((m) => {
          promises.splice(promises.length, 1, CompanyService.grantNewMenuAccess(m))
        })
        await Promise.all(promises)
      } catch (e) {
        this.$notification.error('New Menu Access Error', `Update failed for Access. Error: ${e}`)
      } finally {
        this.$hideSpinner()
        this.$notification.success('New Menu Access', 'Menus Access Granted')
      }
    },
    async alterCompanySettings(name, value) {
      this.$showSpinner()
      this.isLoading = true
      CompanyService.alterCompanySettings(name, value)
        .then(() => {
          this.$hideSpinner()
          this.isLoading = false
          this.$eventHub.$emit('refetchUserCompanyQueries', ['getCompanySetting'])
          this.$notification.success('Setings Updated', 'Settings updated successfully')
        })
        .catch((e) => {
          this.isLoading = false
          this.$hideSpinner()
          this.$notification.error('Company Settings', `Update failed settings for for ${name}. Error: ${e}`)
        })
    }
  },
  beforeRouteEnter(to, from, next) {
    next()
  },
  beforeRouteLeave(to, from, next) {
    next()
  }
}
</script>

<style lang="scss" scoped>
.list-style-reset {
  margin-top: 1em;
  li {
    list-style: initial;
    list-style-type: disc;
    margin-left: 2em;
  }
}
</style>
